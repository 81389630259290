@font-face {
  font-family: 'CustomFont';
  src: url('./SVN-Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'CustomFont';
  src: url('./SVN-Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'CustomFont';
  src: url('./SVN-Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'CustomFont';
  src: url('./SVN-Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@import url('https://fonts.googleapis.com/css2?family=Afacad:wght@400;500;600;700&family=Lexend+Deca:wght@400;500;600;700&family=Mulish:wght@400;500;600;700&family=Play:wght@400;700&display=swap');

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Lexend Deca';
}

.app-logo {
  height: 8vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.site-layout-sub-header-background {
  background: #fff;
  border-bottom: '1px solid #f7f7f7';
  padding: 0 10px;
}

.site-layout-background {
  background: #fff;
}

.site-page-header {
  padding: 0;
}

.custom-steps .ant-steps-item:after {
  width: 9px !important;
  height: 9px !important;
}

.ant-radio-wrapper .ant-radio {
  align-self: baseline;
}
.ant-alert .ant-alert-icon {
  align-self: flex-start;
  margin-top: 3px;
}

.ant-typography {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.container-rotate-90 {
  position: absolute; /* Position it absolutely within its closest positioned ancestor or the viewport */
  top: 50%;
  left: 50%;
  /* Set width to 100vh and height to 100vw to account for the rotation */
  width: 100vh;
  height: 100vw;
  /* Use transform to rotate and shift the element back into full view */
  transform: rotate(90deg) translate(-50%, -50%);
  transform-origin: center center; /* Ensure rotation occurs around the center */
  /* Additional styles for appearance */
  background-color: white;
  border: solid 1px #ccc;
  border-radius: 0.5rem;
}
